import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import moment from "moment";

import axios from "../assets/axios";
import {
  PanelistStacked,
  PanelistLine,
  MobilePanelStacked,
  MobilePanelLine,
  DevicesToPanelistLine,
  DevicesToPanelistStacked,
  DevicesPerPanelistStacked,
  DevicesPerPanelistLine,
  HouseholdsPanelistsStacked,
  HouseholdsPanelistsLine,
} from "../assets/graphs";

import metrics from "./KpiModule";
import auth from "./AuthModule";
import {
  getFormattedDatesFromRequest,
  getFormattedCountrySeries,
} from "./helpers";

export default new Vuex.Store({
  modules: {
    metrics,
    auth,
  },
  state: {
    PanelistStacked,
    PanelistLine,
    MobilePanelStacked,
    MobilePanelLine,
    DevicesToPanelistLine,
    DevicesToPanelistStacked,
    DevicesPerPanelistStacked,
    DevicesPerPanelistLine,
    HouseholdsPanelistsStacked,
    HouseholdsPanelistsLine,
  },
  mutations: {
    loadPanelSizeGraphs(state, { dates, series }) {
      state.PanelistStacked.xAxis.categories = dates;
      state.PanelistStacked.xAxis.max = dates.length - 1.49;
      state.PanelistStacked.series = series;

      state.PanelistLine.xAxis.categories = dates;
      state.PanelistStacked.xAxis.max = dates.length - 1.49;
      state.PanelistLine.series = series;
    },
    loadMobilePanelGraphs(state, { dates, series }) {
      state.MobilePanelStacked.xAxis.categories = dates;
      state.MobilePanelStacked.series = series;

      state.MobilePanelLine.xAxis.categories = dates;
      state.MobilePanelLine.series = series;
    },
    loadDeviceGraph(state, { dates, series }) {
      state.DevicesToPanelistLine.xAxis.categories = dates;
      state.DevicesToPanelistLine.series = series;

      state.DevicesToPanelistStacked.xAxis.categories = dates;
      state.DevicesToPanelistStacked.series = series;
    },
    loadDevicePerPanelist(state, { dates, series }) {
      state.DevicesPerPanelistStacked.xAxis.categories = dates;
      state.DevicesPerPanelistStacked.series = series;

      state.DevicesPerPanelistLine.xAxis.categories = dates;
      state.DevicesPerPanelistLine.series = series;
    },
    loadHouseholdGraph(state, { dates, series }) {
      state.HouseholdsPanelistsStacked.xAxis.categories = dates;
      state.HouseholdsPanelistsStacked.series = series;

      state.HouseholdsPanelistsLine.xAxis.categories = dates;
      state.HouseholdsPanelistsLine.series = series;
    },
  },
  actions: {
    async loadPanels({ commit }) {
      const {
        data: { data },
      } = await axios.get("panels/metrics");

      let countries = _.chain(data).map("country").uniq().value();

      let dates = getFormattedDatesFromRequest(data);
      let panelSeries = getFormattedCountrySeries(countries, data, "panelSize");
      let mobileSeries = getFormattedCountrySeries(
        countries,
        data,
        "probalisticMobileSize"
      );

      commit("loadPanelSizeGraphs", { dates, series: panelSeries });
      commit("loadMobilePanelGraphs", { dates, series: mobileSeries });
    },
    async loadDevices({ commit }) {
      const {
        data: { data },
      } = await axios.get("panels/metrics");

      let countries = _.chain(data).map("country").uniq().value();

      let dates = getFormattedDatesFromRequest(data);

      let tiedToPanelistSeries = getFormattedCountrySeries(
        countries,
        data,
        "devicesTiedToPanelists"
      );

      let perPanelistSeries = getFormattedCountrySeries(
        countries,
        data,
        "devicesPerPanelists" //will have float values
      );

      commit("loadDeviceGraph", { dates, series: tiedToPanelistSeries });
      commit("loadDevicePerPanelist", { dates, series: perPanelistSeries });
    },
    async loadHouseholds({ commit }) {
      const {
        data: { data },
      } = await axios.get("panels/metrics");

      let countries = _.chain(data).map("country").uniq().value();

      let dates = getFormattedDatesFromRequest(data);

      let householdSeries = getFormattedCountrySeries(
        countries,
        data,
        "households"
      );

      commit("loadHouseholdGraph", { dates, series: householdSeries });
      commit("loadHouseholdGraph", { dates, series: householdSeries });
    },
  },
  getters: {
    getPanelistStacked: (state) => state.PanelistStacked,
    getPanelistLine: (state) => state.PanelistLine,
    getMobilePanelStacked: (state) => state.MobilePanelStacked,
    getMobilePanelLine: (state) => state.MobilePanelLine,
    getDevicesToPanelistLine: (state) => state.DevicesToPanelistLine,
    getDevicesToPanelistStacked: (state) => state.DevicesToPanelistStacked,
    getDevicesPerPanelistLine: (state) => state.DevicesPerPanelistLine,
    getDevicesPerPanelistStacked: (state) => state.DevicesPerPanelistStacked,
    getPanelistHouseholdsLine: (state) => state.HouseholdsPanelistsLine,
    getPanelistHouseholdsStacked: (state) => state.HouseholdsPanelistsStacked,
    getPanelNumbers: (state) => (from, to) => {
      let dates = _.filter(state.PanelistLine.xAxis.categories, (date) => {
        return (
          moment(date).isSameOrAfter(from) && moment(date).isSameOrBefore(to)
        );
      });

      return _.flatMap(dates, (date, index) => {
        return _.map(state.PanelistLine.series, (data) => {
          return {
            panelists: data.data[index],
            country: data.name,
            date,
          };
        });
      });
    },
  },
});
