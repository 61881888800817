import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import Devices from "./views/Devices.vue";
import Households from "./views/Households.vue";
import ArLogin from "./views/ArLogin.vue";
import store from "./store/store";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/devices",
      name: "devices",
      component: Devices,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/households",
      name: "households",
      component: Households,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: ArLogin,
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/loadUser");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("idToken")) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
