import router from "./../router";
import axios from "../assets/axios";

export default {
  namespaced: true,
  state: {
    user: {
      name: null,
    },
    isLoggedIn: false,
    isLoading: true,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isLoading = false;
      state.isLoggedIn = true;
    },
    setToken(state, token) {
      localStorage.setItem("idToken", token);
    },
    setLoading(state) {
      state.isLoading = false;
    },
    logout(state) {
      localStorage.removeItem("idToken");

      state.isLoggedIn = false;
    },
  },
  actions: {
    async loadUser({ dispatch, commit }) {
      try {
        const {
          data: { idToken },
        } = await axios.post(
          "https://api.audienceproject.com/sso/v1/cp/token",
          {},
          { withCredentials: true }
        );

        commit("setToken", idToken);
        dispatch("getUserDetails", idToken);
      } catch (error) {
        commit("setLoading");
        router.push({ name: "login" });
      }
    },
    logout({ commit }) {
      commit("logout");
      router.push({ name: "login" });
    },
    async getUserDetails({ commit, rootState }, token) {
      try {
        const {
          data: {
            data: { name, phone, email },
          },
        } = await axios.get("https://api.audienceproject.com/org/v1/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (rootState.route.fullPath === "/login") {
          router.push({ name: "dashboard" });
        }

        commit("setUser", {
          name,
          phone,
          email,
        });
      } catch (error) {
        router.push({ name: "login" });
        commit("setLoading");
      }
    },
  },
  getters: {
    getUser: (state) => state.user,
    isLoggedIn: (state) => state.isLoggedIn,
    isLoading: (state) => state.isLoading,
  },
};
