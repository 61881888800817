<template>
  <div>
    <article class="vh-100 dt w-100">
      <div class="dtc v-mid tc white ph3 ph4-l">
        <img
          src="../assets/audienceproject-logo.png"
          class="w-10 mb1"
          alt="night sky over water"
        />
        <main class="black-80 mt4">
          <a
            href="https://api.audienceproject.com/sso/v1/cp/login?redirectUri=https%3A%2F%2Fdashboard.audienceproject.com"
            class="b ph3 pv2 input-reset bg-transparent grow pointer f6 dib"
          >
            Login
          </a>
        </main>
      </div>
    </article>
  </div>
</template>
