import axios from "../assets/axios";
import _ from "lodash";
import moment from "moment";
import {
  recalculations,
  campaigns,
  adform,
  techedge,
} from "../assets/kpiGraphs";

export default {
  namespaced: true,
  state: {
    recalculations,
    campaigns,
    adform,
    techedge,
  },
  mutations: {
    loadRecalculationData(state, data) {
      let series = _.map(data, (item) => {
        let date = moment(item.Timestamp);
        return [date.unix() * 1000, item.Sum];
      });

      state.recalculations.series = [
        {
          type: "area",
          name: "Recalculations",
          data: series,
        },
      ];
    },
    loadCampaignsData(state, data) {
      _.forEach(data, (item) => {
        item.date = moment(item.date).unix() * 1000;
      });

      state.campaigns.series = [
        {
          type: "area",
          name: "Campaigns",
          data: _.map(data, (item) => [item.date, item.created]),
        },
      ];

      state.techedge.series = [
        {
          type: "area",
          name: "Campaigns",
          data: _.map(data, (item) => [item.date, item.techedge]),
        },
      ];

      state.adform.series = [
        {
          type: "area",
          name: "Campaigns",
          data: _.map(data, (item) => [item.date, item.adform]),
        },
      ];
    },
  },
  actions: {
    async loadRecalculationMetrics({ commit }) {
      const { data } = await axios.get("kpi/recalculation");
      commit("loadRecalculationData", data.data);
    },
    async loadCampaignMetrics({ commit }) {
      const { data } = await axios.get("kpi/campaigns");
      commit("loadCampaignsData", data.data);
    },
  },
  getters: {
    getRecalculations: (state) => state.recalculations,
    getCampaigns: (state) => state.campaigns,
    getTechedge: (state) => state.adform,
    getAdform: (state) => state.techedge,
  },
};
