import axios from "axios";

const Api = axios.create({
  baseURL: `https://kloan90q47.execute-api.us-east-1.amazonaws.com/Prod`,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("idToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
