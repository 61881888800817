<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="bg-white pv3 ph4 center w-30 mt6" @click.stop>
        <!-- header -->
        <div>
          <h3>Export Panels</h3>
          <p>
            Exports panelist numbers for all countries to a CSV file in the
            following format:
            <span class="b">date, country, number of panelists</span>
          </p>
        </div>

        <!-- body -->
        <div class="mt4">
          <p class="w-100">Range:</p>
          <div class="fl w-50 center">
            <DatePicker
              class="center"
              type="month"
              :clearable="false"
              :editable="false"
              v-model="from"
              lang="en"
            />
          </div>
          <div class="fl w-50 center">
            <DatePicker
              class="center"
              type="month"
              :clearable="false"
              :editable="false"
              v-model="to"
              lang="en"
            />
          </div>
        </div>

        <a
          @click="exportCSV()"
          class="f6 mt5 link dim ba ph3 pv2 dib black pointer"
          >Export</a
        >
      </div>
    </div>
  </transition>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "ArExportModal",
  data() {
    return {
      users: [],
      from: moment().subtract(6, "months"),
      to: moment(),
    };
  },
  components: { DatePicker },
  methods: {
    exportCSV() {
      let result = this.getPanelNumbers(this.from, this.to);

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(result[0]).join(","),
        ...result.map((item) => Object.values(item).join(",")),
      ].join("\n");

      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  computed: {
    ...mapGetters(["getPanelNumbers"]),
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

/* transistion styles */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
