<template>
  <nav class="pv2 bb ph5-l">
    <ExportModal v-if="showModal" @close="showModal = false" />
    <div cass="pv2">
      <div class="ph3">
        <router-link
          class="f6 f5-l link black-80 dib pa3 underline-hover"
          to="/"
          >Panels</router-link
        >
        <router-link
          class="f6 f5-l link black-80 dib pa3 underline-hover"
          to="/devices"
          >Devices</router-link
        >
        <router-link
          class="f6 f5-l link black-80 dib pa3 underline-hover"
          to="/households"
          >Households</router-link
        >
        |
        <a
          @click="showModal = true"
          class="f6 f5-l link black-80 dib pa3 underline-hover"
          >Export</a
        >
        <div class="fr f6 f5-l link pa3">
          <div class="di">{{ user.name }}</div>
          <div class="pl2 di">
            <a
              @click="logout"
              class="f6 link dim ba ph2 pv1 mb2 dib black pointer"
              >Logout</a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ExportModal from "./../modals/ExportModal";
export default {
  name: "ArNavbar",
  data: function () {
    return { showModal: false };
  },
  components: {
    ExportModal,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    ...mapActions("auth", ["logout", "loadUser"]),
  },
  created() {
    let refreshTime = 30 * 60 * 1000;
    this.interval = setInterval(() => {
      this.loadUser();
    }, refreshTime);

    this.$notify({
      group: "info",
      type: "error",
      duration: -5000, //if negative - notification will stay forever or until clicked
      title: "Numbers are internal use only",
      text: "Do not share without management approval",
    });
  },
};
</script>
