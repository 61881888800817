// xaxis
const panelDateAxis = {
  tickmarkPlacement: "on",
  categories: [],
  title: {
    enabled: false,
  },
  min: 0.49,
};

const deviceDateAxis = {
  chart: {
    style: {
      fontFamily: "Inter var",
    },
  },
  tickmarkPlacement: "on",
  categories: [],
  title: {
    enabled: false,
  },
  min: 0.49,
};

// plot options
const linePlotOptions = {
  line: {
    marker: {
      enabled: false,
    },
  },
};

const stackedPlotOptions = {
  area: {
    stacking: "normal",
    lineColor: "#666666",
    lineWidth: 1,
    marker: {
      enabled: false,
    },
  },
};

// tooltip
const stackedTooltip = {
  shared: true,
  headerFormat: "Date: {point.x}</b><br>",
  pointFormat:
    "<b>{series.name}:</b> {point.y} ( {point.percentage:.0f}% )<br>",
  footerFormat: "<b>Total:  {point.total} </b>",
};

const lineTooltip = {
  headerFormat: "Date: {point.x}</b><br>",
  pointFormat: "<b>{series.name}:</b> {point.y}<br>",
};

// graphs
const PanelistStacked = {
  chart: {
    type: "area",
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: stackedTooltip,
  credits: false,
  xAxis: panelDateAxis,
  yAxis: {
    title: {
      text: "Panelists",
    },
  },
  title: {
    text: "Total panelists across countries (stacked)",
  },
  plotOptions: stackedPlotOptions,
  series: [],
};

const PanelistLine = {
  chart: {
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: lineTooltip,
  credits: false,
  xAxis: panelDateAxis,
  yAxis: {
    title: {
      text: "Panelists",
    },
  },
  plotOptions: linePlotOptions,
  title: {
    text: "Total panelists across countries",
  },
  series: [],
};

const MobilePanelLine = {
  chart: {
    height: "50%",
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: lineTooltip,
  plotOptions: linePlotOptions,
  credits: false,
  xAxis: panelDateAxis,
  yAxis: {
    title: {
      text: "Mobile IDs",
    },
  },
  title: {
    text: "Mobile panel",
  },
  subtitle: {
    text: "Mobile IDs probabilistically tied to a known panelist",
  },
  series: [],
};

const MobilePanelStacked = {
  chart: {
    type: "area",
    height: "50%",
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: stackedTooltip,
  credits: false,
  xAxis: panelDateAxis,
  plotOptions: stackedPlotOptions,
  yAxis: {
    title: {
      text: "Mobile IDs",
    },
  },
  title: {
    text: "Mobile panel (stacked)",
  },
  subtitle: {
    text: "Mobile IDs probabilistically tied to a known panelist",
  },
  series: [],
};

const DevicesToPanelistStacked = {
  chart: {
    type: "area",
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: stackedTooltip,
  credits: false,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Panelists",
    },
  },
  plotOptions: stackedPlotOptions,
  title: {
    text: "Devices tied to panelists",
  },
  series: [],
};

const DevicesToPanelistLine = {
  credits: false,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Devices",
    },
  },
  tooltip: lineTooltip,
  plotOptions: linePlotOptions,
  title: {
    text: "Devices tied to panelists",
  },
  series: [],
};

const DevicesPerPanelistStacked = {
  chart: {
    type: "area",
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: stackedTooltip,
  credits: false,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Devices per panelist",
    },
  },
  plotOptions: stackedPlotOptions,
  title: {
    text: "Average devices per panelist",
  },
  series: [],
};

const DevicesPerPanelistLine = {
  credits: false,
  plotOptions: linePlotOptions,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Devices per panelist",
    },
  },
  tooltip: lineTooltip,
  title: {
    text: "Average devices per panelist",
  },
  series: [],
};

const HouseholdsPanelistsStacked = {
  chart: {
    type: "area",
    style: {
      fontFamily: "Inter var",
    },
  },
  credits: false,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Households with panelists",
    },
  },
  tooltip: stackedTooltip,
  plotOptions: stackedPlotOptions,
  title: {
    text: "Households with panelists",
  },
  series: [],
};

const HouseholdsPanelistsLine = {
  chart: {
    style: {
      fontFamily: "Inter var",
    },
  },
  tooltip: lineTooltip,
  credits: false,
  plotOptions: linePlotOptions,
  xAxis: deviceDateAxis,
  yAxis: {
    title: {
      text: "Households with panelists",
    },
  },
  title: {
    text: "Households with panelists",
  },
  series: [],
};

export {
  PanelistStacked,
  PanelistLine,
  MobilePanelStacked,
  MobilePanelLine,
  DevicesToPanelistLine,
  DevicesToPanelistStacked,
  DevicesPerPanelistStacked,
  DevicesPerPanelistLine,
  HouseholdsPanelistsLine,
  HouseholdsPanelistsStacked,
};
