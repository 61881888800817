import moment from "moment";
import _ from "lodash";

export function getFormattedDatesFromRequest(data) {
  return _.chain(data)
    .map((row) => {
      return moment(row.date).format("YYYY-MM-DD");
    })
    .uniq()
    .value();
}

export function getFormattedCountrySeries(countries, data, key) {
  return _.map(countries, (country) => {
    let countryData = _.map(_.filter(data, { country }), (row) => {
      return _.toNumber(row[key]);
    });

    return {
      name: country,
      data: countryData,
    };
  });
}
