<template>
  <div>
    <highcharts
      class="w-50 ph2 center"
      :options="householdsStackedGraph"
    ></highcharts>
    <highcharts
      class="w-50 ph2 mt4 center"
      :options="householdsLineGraph"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapGetters } from "vuex";

export default {
  name: "ArHouseholds",
  computed: {
    ...mapGetters({
      householdsStackedGraph: "getPanelistHouseholdsStacked",
      householdsLineGraph: "getPanelistHouseholdsLine",
    }),
  },
  created() {
    this.$store.dispatch("loadHouseholds");
  },
  components: {
    highcharts: Chart,
  },
};
</script>
