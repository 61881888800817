<template>
  <div>
    <highcharts
      class="w-50 fl center ph2"
      :options="devicesToPanelistStackedGraph"
    ></highcharts>
    <highcharts
      class="w-50 fl center ph2"
      :options="devicesToPanelistLineGraph"
    ></highcharts>

    <highcharts
      class="w-50 fl center ph2"
      :options="devicesPerPanelistStackedGraph"
    ></highcharts>
    <highcharts
      class="w-50 fl center ph2"
      :options="devicesPerPanelistLineGraph"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapGetters } from "vuex";

export default {
  name: "ArDevices",
  computed: {
    ...mapGetters({
      devicesToPanelistLineGraph: "getDevicesToPanelistLine",
      devicesToPanelistStackedGraph: "getDevicesToPanelistStacked",
      devicesPerPanelistLineGraph: "getDevicesPerPanelistLine",
      devicesPerPanelistStackedGraph: "getDevicesPerPanelistStacked",
    }),
  },
  created() {
    this.$store.dispatch("loadDevices");
  },
  components: {
    highcharts: Chart,
  },
};
</script>
