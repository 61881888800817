<template>
  <div id="app">
    <notifications
      classes="custom-notification f5 pa3 mr3"
      animation-type="velocity"
      position="bottom right"
      group="info"
    />
    <div
      v-if="
        !isLoading && isLoggedIn && this.$router.currentRoute.path !== '/login'
      "
    >
      <Navbar />
      <div class="mt4">
        <router-view />
      </div>
    </div>
    <div v-else-if="isLoading && this.$router.currentRoute.path !== '/login'">
      Loading....
    </div>
    <div v-else><LoginPage /></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "./components/Navbar";
import LoginPage from "./views/ArLogin";

export default {
  name: "App",
  components: {
    Navbar,
    LoginPage,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isLoading: "auth/isLoading",
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
};
</script>

<style>
@import url("https://rsms.me/inter/inter.css");
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.router-link-exact-active {
  color: #42b983;
  text-decoration: underline;
}

.custom-notification {
  color: #ffffff;
  border-left: 5px solid #187fe7;
}
.custom-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}
.custom-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}
.custom-notification.success {
  background: #68cd86;
  border-left-color: #42a85f;
}
</style>
