<template>
  <div class="ph3">
    <!-- totals -->
    <highcharts
      class="w-50 fl center ph2"
      :options="panelistStackedGraph"
    ></highcharts>
    <highcharts
      class="w-50 fl center ph2"
      :options="panelistLineGraph"
    ></highcharts>

    <!-- mobile panel -->
    <highcharts
      class="w-50 fl center ph2 mt4"
      :options="mobilePanelStacked"
    ></highcharts>
    <highcharts
      class="w-50 fl center ph2 mt4"
      :options="mobilePanelLine"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapGetters } from "vuex";

export default {
  name: "ArDashboard",
  components: {
    highcharts: Chart,
  },
  computed: {
    ...mapGetters({
      panelistStackedGraph: "getPanelistStacked",
      panelistLineGraph: "getPanelistLine",
      mobilePanelStacked: "getMobilePanelStacked",
      mobilePanelLine: "getMobilePanelLine",
    }),
  },
  created() {
    this.$store.dispatch("loadPanels");
  },
};
</script>
