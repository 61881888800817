const recalculations = {
  chart: {
    zoomType: "x",
  },
  credits: false,
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "recalculations",
    },
  },
  title: {
    text: "AudienceReport: Total Recalculations",
  },
  legend: {
    enabled: false,
  },
  series: [],
};

const campaigns = {
  chart: {
    zoomType: "x",
  },
  credits: false,
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "campaigns",
    },
  },
  title: {
    text: "AudienceReport: Campaigns created",
  },
  legend: {
    enabled: false,
  },
  series: [],
};

const adform = {
  chart: {
    zoomType: "x",
  },
  credits: false,
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "campaigns",
    },
  },
  title: {
    text: "AudienceReport: Adform campaigns",
  },
  legend: {
    enabled: false,
  },
  series: [],
};

const techedge = {
  chart: {
    zoomType: "x",
  },
  credits: false,
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "campaigns",
    },
  },
  title: {
    text: "AudienceReport: Techedge campaigns created",
  },
  legend: {
    enabled: false,
  },
  series: [],
};

export { recalculations, campaigns, adform, techedge };
