import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";
import { sync } from "vuex-router-sync";

import HighchartsVue from "highcharts-vue";
import Notifications from "@kyvg/vue3-notification";
import velocity from "velocity-animate";

sync(store, router);

createApp(App)
  .use(router)
  .use(store)
  .use(HighchartsVue)
  .use(Notifications, { velocity })
  .mount("#app");
